import {useFetcher, useMatches} from '@remix-run/react';
import {useEffect, useRef} from 'react';
import {useInView} from 'react-intersection-observer';
import {CountrySelector} from './CountrySelector';
import {LanguageSelector} from './LanguageSelector';

export default function LocalizationSelector() {
  const fetcher = useFetcher();
  const [root] = useMatches();
  const pathPrefix = root.data?.i18n.pathPrefix;
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const observerRef = useRef(null);
  useEffect(() => {
    ref(observerRef.current);
  }, [ref, observerRef]);

  // Get available countries list when in view
  useEffect(() => {
    if (!inView || fetcher.data || fetcher.state === 'loading') return;
    fetcher.load(pathPrefix + '/api/localization');
  }, [inView, fetcher, pathPrefix]);

  return (
    <section ref={observerRef}>
      <CountrySelector availableCountries={fetcher.data?.availableCountries} />
      <LanguageSelector availableCountries={fetcher.data?.availableCountries} />
    </section>
  );
}
