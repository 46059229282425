import {useFetcher, useLocation, useMatches} from '@remix-run/react';
import {Heading, Button, IconCheck} from '~/components';
import {useCallback, useEffect, useRef, useState} from 'react';
import {CartAction, I18nLocale} from '~/lib/type';
import {getPathPrefix} from '~/lib/utils';
import clsx from 'clsx';
import {
  CartBuyerIdentityInput,
  Country,
} from '@shopify/hydrogen/storefront-api-types';

export function CountrySelector({
  availableCountries,
}: {
  availableCountries: Country[];
}) {
  const [root] = useMatches();
  const closeRef = useRef<HTMLDetailsElement>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const i18n = root.data?.i18n as I18nLocale;

  useEffect(() => {
    if (availableCountries?.length) {
      const selectedCountry = availableCountries?.find(
        (country) => country.isoCode === i18n.countryCode,
      );
      setSelectedCountry(selectedCountry);
    }
  }, [availableCountries, i18n]);

  const closeDropdown = useCallback(() => {
    closeRef.current?.removeAttribute('open');
  }, []);

  return (
    <section
      className="grid w-full gap-4 md:max-w-xs md:ml-auto"
      onMouseLeave={closeDropdown}
    >
      <Heading size="lead" className="cursor-default" as="h3">
        Country
      </Heading>
      <div className="relative">
        <details
          className="w-full border rounded border-contrast/30 dark:border-white open:round-b-none overflow-clip"
          ref={closeRef}
        >
          <summary className="flex items-center justify-between w-full px-4 py-3 cursor-pointer">
            {selectedCountry?.name}
          </summary>
          <div className="w-full overflow-auto border-t border-contrast/30 dark:border-white bg-contrast/30 max-h-36">
            {availableCountries &&
              availableCountries.map((country) => {
                const isSelected = country.isoCode === selectedCountry?.isoCode;
                const countryUrlPath = getPathPrefix({
                  countryCode: country.isoCode,
                  languageCode: country.availableLanguages[0]?.isoCode,
                });

                return (
                  <CountryForm
                    key={country.isoCode}
                    closeDropdown={closeDropdown}
                    countryUrlPath={countryUrlPath}
                    isSelected={isSelected}
                    country={country}
                  />
                );
              })}
          </div>
        </details>
      </div>
    </section>
  );
}

function CountryForm({
  closeDropdown,
  country,
  countryUrlPath,
  isSelected,
}: {
  closeDropdown: () => void;
  country: Country;
  countryUrlPath: string;
  isSelected: boolean;
}) {
  return (
    <ChangeLocaleForm
      key={country.isoCode}
      redirectTo={countryUrlPath}
      buyerIdentity={{
        countryCode: country.isoCode,
      }}
    >
      <Button
        className={clsx([
          'text-contrast dark:text-primary',
          'bg-primary dark:bg-contrast w-full p-2 transition rounded flex justify-start',
          'items-center text-left cursor-pointer py-2 px-4',
        ])}
        type="submit"
        variant="primary"
        onClick={closeDropdown}
      >
        {country.name}
        {isSelected ? (
          <span className="ml-2">
            <IconCheck />
          </span>
        ) : null}
      </Button>
    </ChangeLocaleForm>
  );
}

function ChangeLocaleForm({
  children,
  buyerIdentity,
  redirectTo,
}: {
  children: React.ReactNode;
  buyerIdentity: CartBuyerIdentityInput;
  redirectTo: string;
}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.UPDATE_BUYER_IDENTITY}
      />
      <input
        type="hidden"
        name="buyerIdentity"
        value={JSON.stringify(buyerIdentity)}
      />
      <input type="hidden" name="redirectTo" value={redirectTo} />
      {children}
    </fetcher.Form>
  );
}
