import {useMatches} from '@remix-run/react';
import {Heading, Button, IconCheck} from '~/components';
import {useCallback, useEffect, useRef, useState} from 'react';
import {I18nLocale} from '~/lib/type';
import {getPathPrefix, translate} from '~/lib/utils';
import clsx from 'clsx';
import {
  Country,
  Language,
  LanguageCode,
} from '@shopify/hydrogen/storefront-api-types';
import invariant from 'tiny-invariant';

export function LanguageSelector({
  availableCountries,
}: {
  availableCountries: Country[];
}) {
  const [root] = useMatches();
  const closeRef = useRef<HTMLDetailsElement>(null);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();
  const i18n = root.data?.i18n as I18nLocale;

  useEffect(() => {
    if (availableCountries?.length) {
      const selectedCountry = availableCountries.find(
        (country) => country.isoCode === i18n.countryCode,
      );
      invariant(selectedCountry, 'Country not found');
      setLanguages(selectedCountry.availableLanguages);

      const language = selectedCountry.availableLanguages.find(
        (language) => language.isoCode === i18n.languageCode,
      );

      invariant(language, 'Language not found');
      setSelectedLanguage(language);
    }
  }, [availableCountries, i18n]);

  const closeDropdown = useCallback(() => {
    closeRef.current?.removeAttribute('open');
  }, [closeRef]);

  const onChangeLanguage = useCallback(
    (languageIsoCode: LanguageCode) => {
      closeDropdown();

      const pathPrefix = getPathPrefix({
        countryCode: i18n.countryCode,
        languageCode: languageIsoCode,
      });

      // NOTE: navigate() loads ony the _data: routes/($lang)/index because nothing changed in the root
      // we'ven't POST nothing it's only a navigation So the server.js is not run and the new dictionary is not loaded

      // NOTE: In country selector we do a form submit and this force the server.js to run to get the new root data
      // here we don't need to do a form submit because we don't need to change the user identity
      window.location.href = pathPrefix || '/';
    },
    [closeDropdown, i18n],
  );

  return (
    <section
      className="grid w-full gap-4 md:max-w-xs md:ml-auto mt-8"
      onMouseLeave={closeDropdown}
    >
      <Heading size="lead" className="cursor-default" as="h3">
        {translate({t: 'label.language'})}
      </Heading>
      <div className="relative">
        <details
          className="w-full border rounded border-contrast/30 dark:border-white open:round-b-none overflow-clip"
          ref={closeRef}
        >
          <summary className="flex items-center justify-between w-full px-4 py-3 cursor-pointer">
            {selectedLanguage?.endonymName}
          </summary>
          <div className="w-full overflow-auto border-t border-contrast/30 dark:border-white bg-contrast/30 max-h-36">
            {languages &&
              languages.map((language) => {
                const isSelected =
                  language.isoCode === selectedLanguage?.isoCode;
                return (
                  <Button
                    key={language.isoCode}
                    className={clsx([
                      'text-contrast dark:text-primary',
                      'bg-primary dark:bg-contrast w-full p-2 transition rounded flex justify-start',
                      'items-center text-left cursor-pointer py-2 px-4',
                    ])}
                    type="button"
                    variant="primary"
                    onClick={() => onChangeLanguage(language.isoCode)}
                  >
                    {language.endonymName}
                    {isSelected ? (
                      <span className="ml-2">
                        <IconCheck />
                      </span>
                    ) : null}
                  </Button>
                );
              })}
          </div>
        </details>
      </div>
    </section>
  );
}
