import {
  type EnhancedMenu,
  type EnhancedMenuItem,
  useIsHomePath,
  translate,
} from '~/lib/utils';
import {
  Drawer,
  useDrawer,
  Text,
  Input,
  IconAccount,
  IconBag,
  IconSearch,
  Heading,
  IconMenu,
  IconCaret,
  Section,
  Cart,
  CartLoading,
  Link,
} from '~/components';
import {Await, useMatches} from '@remix-run/react';
import {Disclosure, Transition} from '@headlessui/react';
import {
  startTransition,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useIsHydrated} from '~/hooks/useIsHydrated';
import {useCartFetchers} from '~/hooks/useCartFetchers';
import type {LayoutData} from '../root';
import {Collection} from '@shopify/hydrogen/storefront-api-types';
import {Image} from '@shopify/hydrogen';
import {Icon} from '@iconify/react';
import LocalizationSelector from './LocalizationSelector';

export function Layout({
  children,
  layout,
}: {
  children: React.ReactNode;
  layout: LayoutData;
}) {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="">
          <a href="#mainContent" className="sr-only">
            Skip to content
          </a>
        </div>
        <Header
          title={layout?.shop.name ?? 'Hydrogen'}
          menu={layout?.headerMenu}
          collections={layout?.collections}
        />
        <main role="main" id="mainContent" className="flex-grow">
          {children}
        </main>
      </div>
      <Footer menu={layout?.footerMenu} />
    </>
  );
}

function Header({
  title,
  menu,
  collections,
}: {
  title: string;
  menu?: EnhancedMenu;
  collections?: Collection[];
}) {
  const isHome = useIsHomePath();

  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // toggle cart drawer when adding to cart
  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);

  return (
    <>
      <CartDrawer
        isOpen={isCartOpen}
        onClose={closeCart}
        addToCartFetchers={addToCartFetchers}
      />
      {menu && (
        <MenuDrawer isOpen={isMenuOpen} onClose={closeMenu} menu={menu} />
      )}
      <DesktopHeader
        isHome={isHome}
        title={title}
        menu={menu}
        openCart={openCart}
        collections={collections}
      />
      <MobileHeader
        isHome={isHome}
        title={title}
        openCart={openCart}
        openMenu={openMenu}
      />
    </>
  );
}

function CartDrawer({
  isOpen,
  onClose,
  addToCartFetchers,
}: {
  isOpen: boolean;
  onClose: () => void;
  addToCartFetchers: ReturnType<typeof useCartFetchers>;
}) {
  const [root] = useMatches();

  return (
    <Drawer open={isOpen} onClose={onClose} heading="Cart" openFrom="right">
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cart}>
            {(cart) => {
              return (
                <>
                  {addToCartFetchers[0]?.state === 'loading' ||
                  addToCartFetchers[0]?.state === 'submitting' ? (
                    <CartLoading />
                  ) : (
                    <Cart layout="drawer" onClose={onClose} cart={cart} />
                  )}
                  ;
                </>
              );
            }}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}

export function MenuDrawer({
  isOpen,
  onClose,
  menu,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: EnhancedMenu;
}) {
  return (
    <Drawer open={isOpen} onClose={onClose} openFrom="left" heading="Menu">
      <div className="grid h-full">
        <MenuMobileNav menu={menu} onClose={onClose} />
      </div>
    </Drawer>
  );
}

function MenuMobileNav({
  menu,
  onClose,
}: {
  menu: EnhancedMenu;
  onClose: () => void;
}) {
  return (
    <nav className="grid gap-4 p-6 sm:gap-6 sm:px-12 sm:py-8 items-start h-full overflow-y-auto">
      {/* Top level menu items */}
      <div className="grid gap-4">
        <TreeMenuMobile items={menu.items} onClose={onClose} />
      </div>
    </nav>
  );
}

function TreeMenuMobile({
  items,
  onClose,
}: {
  items: EnhancedMenuItem[];
  onClose: () => void;
}) {
  return (
    <>
      {(items || []).map((item) => (
        <RowMenuMobile
          key={item.id}
          item={item}
          onClose={onClose}
        ></RowMenuMobile>
      ))}
    </>
  );
}

function RowMenuMobile({
  item,
  onClose,
}: {
  item: EnhancedMenuItem;
  onClose: () => void;
}) {
  const [isVisible, setIsVisible] = useState(false);
  const expand = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div className="grid gap-4">
        <div className="flex justify-between w-full">
          <Link
            to={item.to}
            target={item.target}
            onClick={() => startTransition(onClose)}
          >
            <Text as="span" size="copy">
              {item.title}
            </Text>
          </Link>
          {item.items?.length > 0 && (
            <Icon
              icon={isVisible ? 'ic:baseline-minus' : 'ic:baseline-plus'}
              onClick={expand}
            />
          )}
        </div>
        {item.items?.length > 0 && isVisible && (
          <div className="grid gap-4 ml-3">
            <TreeMenuMobile items={item.items} onClose={onClose} />
          </div>
        )}
      </div>
    </>
  );
}

function MobileHeader({
  title,
  isHome,
  openCart,
  openMenu,
}: {
  title: string;
  isHome: boolean;
  openCart: () => void;
  openMenu: () => void;
}) {
  const searchI18n = translate({t: 'label.search'});

  const styles = {
    button: 'relative flex items-center justify-center w-8 h-8',
    container: `${
      isHome
        ? 'bg-contrast/60 text-primary shadow-darkHeader'
        : 'bg-contrast/80 text-primary'
    } flex lg:hidden items-center h-nav sticky backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-4 px-4 md:px-8`,
  };

  return (
    <header role="banner" className={styles.container}>
      <div className="flex items-center justify-start w-full gap-4">
        <button onClick={openMenu} className={styles.button}>
          <IconMenu />
        </button>
        <form action="/search" className="items-center gap-2 sm:flex">
          <button type="submit" className={styles.button}>
            <IconSearch />
          </button>
          <Input
            className={
              isHome
                ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                : 'focus:border-primary/20'
            }
            type="search"
            variant="minisearch"
            placeholder={searchI18n}
            name="q"
          />
        </form>
      </div>
      <Link
        className="flex items-center self-stretch justify-center flex-grow w-full h-full"
        to="/"
      >
        <Image
          alt={title}
          data={{
            width: 200,
            height: 31,
            url: 'https://cdn.shopify.com/s/files/1/0551/5290/2227/files/kocca_logo_200x_2x_05d45137-4128-4e8e-bb76-45a66289ddcc.webp?v=1670940386',
          }}
          loaderOptions={{width: 100}}
          loading="lazy"
        ></Image>
      </Link>

      <div className="flex items-center justify-end w-full gap-4">
        <Link to={'/account'} className={styles.button}>
          <IconAccount />
        </Link>
        <CartCount isHome={isHome} openCart={openCart} />
      </div>
    </header>
  );
}

function DesktopHeader({
  isHome,
  menu,
  openCart,
  title,
  collections,
}: {
  isHome: boolean;
  openCart: () => void;
  menu?: EnhancedMenu;
  title: string;
  collections?: Collection[];
}) {
  const searchI18n = translate({t: 'label.search'});
  const [submenuItems, setSubmenuItems] = useState<EnhancedMenuItem[]>();
  const [highlightedItems, setHighlightedItems] =
    useState<EnhancedMenuItem[]>();
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const showSubmenu = useCallback(
    (items: any) => {
      const _highlightedItems = items.map((item: any) =>
        item.items?.filter((thirdLevel: any) => {
          const metafields = collections?.find(
            (collection) => collection.id === thirdLevel.resourceId,
          )?.metafields;

          const highlightMetafield = metafields?.find(
            (meta) => meta?.key === 'is_highlight',
          );
          if (highlightMetafield?.value === 'true') {
            return true;
          }
          return null;
        }),
      );

      const highlightedItems: any[] = [];
      _highlightedItems.reduce(
        (accumulator: any, item: any) => highlightedItems.push(...item),
        [],
      );

      setSubmenuItems(items);
      setIsSubmenuOpen(items.length > 0);
      setHighlightedItems(highlightedItems);
    },
    [collections],
  );

  const closeSubmenu = useCallback(() => {
    setIsSubmenuOpen(false);
  }, []);

  const styles = {
    button:
      'relative flex items-center justify-center w-8 h-8 focus:ring-primary/5',
    container: `bg-white text-grey-900 shadow-darkHeader hidden h-nav lg:flex items-center sticky transition duration-300 backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-8 px-12 z-50`,
  };

  return (
    <header
      role="banner"
      className={styles.container}
      onMouseLeave={closeSubmenu}
    >
      <Link to="/">
        {/* DOJO qua c'era un if che stampava il titolo del sito se non presente l'oggetto shop
        perche' questo viene usato come fallback, ma questo generava un layout shift tra il primo rendering
        ed il secondo con l'immagine caricata, siccome e' un asset statico (logo) lo possiamo caricare anche da server */}
        <Image
          alt={title}
          data={{
            width: 200,
            height: 31,
            url: 'https://cdn.shopify.com/s/files/1/0551/5290/2227/files/kocca_logo_200x_2x_05d45137-4128-4e8e-bb76-45a66289ddcc.webp?v=1670940386',
          }}
          loaderOptions={{width: 200, height: 31}}
          loading="lazy"
        ></Image>
      </Link>
      <nav className="flex h-full items-center flex-1 justify-center gap-8">
        {/* Top level menu items */}
        {(menu?.items || []).map((item) => (
          <div
            key={item.id}
            className="flex items-center h-full border-t-4 border-b-4 border-transparent hover:border-b-contrast"
          >
            <Link
              to={item.to}
              target={item.target}
              onMouseEnter={() => showSubmenu(item.items)}
              onClick={closeSubmenu}
            >
              <span className="font-medium">{item.title}</span>
            </Link>
          </div>
        ))}
      </nav>
      <Transition
        show={isSubmenuOpen}
        enter="transition-opacity duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute top-navbar left-0 bg-contrast w-full shadow"
      >
        {/* Submenu items */}
        <div className="mx-auto max-w-7xl px-8 text-primary hidden md:block">
          <div className="flex justify-between gap-y-10 gap-x-8 py-8 auto-cols-fr">
            <div className="flex flex-grow gap-8">
              {(submenuItems || []).map((secondLevel) => {
                return secondLevelMenu(collections, secondLevel, closeSubmenu);
              })}
            </div>
            <div className="flex gap-4">
              {(highlightedItems || [])
                .filter((i) => i)
                .map((highlightedItem) => {
                  return secondLevelMenu(
                    collections,
                    highlightedItem,
                    closeSubmenu,
                  );
                })}
            </div>
          </div>
        </div>
      </Transition>

      <div className="flex items-center gap-1">
        <form action="/search" className="flex items-center gap-2">
          <Input
            className={
              isHome
                ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                : 'focus:border-primary/20'
            }
            type="search"
            variant="minisearch"
            placeholder={searchI18n}
            name="q"
          />
          <button type="submit" className={styles.button}>
            <IconSearch />
          </button>
        </form>
        <Link to={'/account'} className={styles.button}>
          <IconAccount />
        </Link>
        <CartCount isHome={isHome} openCart={openCart} />
      </div>
    </header>
  );
}

function secondLevelMenu(
  collections: any,
  secondLevel: any,
  closeSubmenu: any,
) {
  const collection = collections?.find(
    (collection: any) => collection.id === secondLevel?.resourceId,
  );

  const collectionMediaImage: any = collection?.metafields?.find(
    (metafield: any) => metafield?.key === 'menu_image',
  )?.reference as MediaImage;

  return (
    <div className="flex flex-col max-w-xs" key={secondLevel.id}>
      <Link
        to={secondLevel.to}
        target={secondLevel.target}
        onClick={closeSubmenu}
        className="flex flex-col mb-3"
      >
        {collectionMediaImage?.image && (
          <div className="aspect-[3/2] overflow-hidden rounded-md group-hover:opacity-7 mb-3">
            <Image
              data={collectionMediaImage.image}
              alt={secondLevel.title}
              className="object-cover object-center"
            />
          </div>
        )}
        <span className="font-bold">{secondLevel.title}</span>
      </Link>
      <div className="flex flex-col gap-4">
        {(secondLevel.items || []).map((thirdLevelItem: any) => (
          <Link
            key={thirdLevelItem.id}
            to={thirdLevelItem.to}
            target={thirdLevelItem.target}
            onClick={closeSubmenu}
          >
            <span> {thirdLevelItem.title}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}

function CartCount({
  isHome,
  openCart,
}: {
  isHome: boolean;
  openCart: () => void;
}) {
  const [root] = useMatches();

  return (
    <Suspense fallback={<Badge count={0} dark={isHome} openCart={openCart} />}>
      <Await resolve={root.data?.cart}>
        {(cart) => (
          <Badge
            dark={isHome}
            openCart={openCart}
            count={cart?.totalQuantity || 0}
          />
        )}
      </Await>
    </Suspense>
  );
}

function Badge({
  openCart,
  dark,
  count,
}: {
  count: number;
  dark: boolean;
  openCart: () => void;
}) {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <>
        <IconBag />
        <div
          className={`${
            dark
              ? 'text-primary bg-contrast dark:text-contrast dark:bg-primary'
              : 'text-contrast bg-primary'
          } absolute bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px`}
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count, dark],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}

function Footer({menu}: {menu?: EnhancedMenu}) {
  const isHome = useIsHomePath();
  const itemsCount = menu
    ? menu?.items?.length + 1 > 4
      ? 4
      : menu?.items?.length + 1
    : [];

  return (
    <Section
      divider={isHome ? 'none' : 'top'}
      as="footer"
      role="contentinfo"
      className={`grid min-h-[25rem] items-start grid-flow-row w-full gap-6 py-8 px-6 md:px-8 lg:px-12 md:gap-8 lg:gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-${itemsCount}
        bg-primary dark:bg-contrast dark:text-primary text-contrast overflow-hidden`}
    >
      <FooterMenu menu={menu} />
      <div className="flex flex-col">
        <LocalizationSelector />
      </div>
      <div
        className={`self-end pt-8 opacity-50 md:col-span-2 lg:col-span-${itemsCount}`}
      >
        &copy; {new Date().getFullYear()} / Shopify, Inc. Hydrogen is an MIT
        Licensed Open Source project.
        <Link to="/tooa/artista-board/playground">bt</Link>
      </div>
    </Section>
  );
}

const FooterLink = ({item}: {item: EnhancedMenuItem}) => {
  if (item.to.startsWith('http')) {
    return (
      <a href={item.to} target={item.target} rel="noopener noreferrer">
        {item.title}
      </a>
    );
  }

  return (
    <Link to={item.to} target={item.target} prefetch="intent">
      {item.title}
    </Link>
  );
};

function FooterMenu({menu}: {menu?: EnhancedMenu}) {
  const styles = {
    section: 'grid gap-4',
    nav: 'grid gap-2 pb-6',
  };

  return (
    <>
      {(menu?.items || []).map((item: EnhancedMenuItem) => (
        <section key={item.id} className={styles.section}>
          <Disclosure>
            {({open}) => (
              <>
                <Disclosure.Button className="text-left md:cursor-default">
                  <Heading className="flex justify-between" size="lead" as="h3">
                    {item.title}
                    {item?.items?.length > 0 && (
                      <span className="md:hidden">
                        <IconCaret direction={open ? 'up' : 'down'} />
                      </span>
                    )}
                  </Heading>
                </Disclosure.Button>
                {item?.items?.length > 0 ? (
                  <div
                    className={`${
                      open ? `max-h-48 h-fit` : `max-h-0 md:max-h-fit`
                    } overflow-hidden transition-all duration-300`}
                  >
                    <Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
                      <Disclosure.Panel static>
                        <nav className={styles.nav}>
                          {item.items.map((subItem) => (
                            <FooterLink key={subItem.id} item={subItem} />
                          ))}
                        </nav>
                      </Disclosure.Panel>
                    </Suspense>
                  </div>
                ) : null}
              </>
            )}
          </Disclosure>
        </section>
      ))}
    </>
  );
}
