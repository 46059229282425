import type {LanguageCode} from '@shopify/hydrogen/storefront-api-types';

export declare type TOptionName = 'color' | 'size';
const MAPPED_OPTIONS_NAME: {
  [key in LanguageCode]?: {[key in TOptionName]: string};
} = {
  IT: {
    color: 'color',
    size: 'taglia',
  },
  EN: {
    color: 'color',
    size: 'size',
  },
};

const MAPPED_OPTIONS_VALUES: {
  [key in LanguageCode]?: {[key: string]: IMappedOptionValue};
} = {
  IT: {
    nero: {
      hex: '#000000',
    },
    bianca: {
      hex: '#ffffff',
    },
    arancio: {
      hex: '#fa783c',
    },
    giallo: {
      hex: '#ffff00',
    },
    bordeaux: {
      hex: '#800020',
    },
    rosso: {
      hex: '#ff0000',
    },
    blu: {
      hex: '#0000ff',
    },
    marrone: {
      hex: '#a52a2a',
    },
    magenta: {
      hex: '#ff00ff',
    },
    'fant.tabacco/verde': {
      hex: '#c8b2c8',
    },
    'fant.lilla/marrone': {
      hex: '#c8b2c8',
    },
    crema: {
      hex: '#F5F5DC',
    },
    azalea: {
      hex: '#FFA6C9',
    },
    nocciola: {
      hex: '#A67B5B',
    },
    'verde polvere': {
      hex: '#d5e3c0',
    },
    'rosa delicato': {
      hex: '#F4C2C2',
    },
    'blu notte': {
      hex: '#191970',
    },
    'fant.verde acqua/panna': {
      hex: '#83d8bc',
    },
    'fant.beige/azzurro/giallo': {
      hex: '#6380de',
    },
    'fant.cammello/azzurro': {
      hex: '#77dbf2',
    },
    'fant.giallo/arancio': {
      hex: '#e97a1a',
    },
    'grigio chiaro melange': {
      hex: '#D3D3D3',
    },
    fuxia: {
      hex: '#FF00FF',
    },
    'azzurro polvere': {
      hex: '#B0E0E6',
    },
    'giallo scuro': {
      hex: '#f1ed00',
    },
    'fant.cipria/blu/cuoio': {
      hex: '#bc6400',
    },
    'fant.arancio/nero': {
      hex: '#000000',
    },
    'rosa lillato': {
      hex: '#F8D0FF',
    },
    rosa: {
      hex: '#ffbce7',
    },
    beige: {
      hex: '#F5F5DC',
    },
  },
  EN: {
    black: {
      hex: '#000000',
    },
    white: {
      hex: '#ffffff',
    },
    blue: {
      hex: '#0000ff',
    },
    red: {
      hex: '#ff0000',
    },
    arancio: {
      hex: '#fa783c',
    },
    magenta: {
      hex: '#ff00ff',
    },
    'fant.tabacco/verde': {
      hex: '##c8b2c8',
    },
    'fant.lilla/marrone': {
      hex: '##c8b2c8',
    },
    crema: {
      hex: '#F5F5DC',
    },
    azalea: {
      hex: '#FFA6C9',
    },
    nocciola: {
      hex: '#A67B5B',
    },
    'verde polvere': {
      hex: '#d5e3c0',
    },
    'rosa delicato': {
      hex: '#F4C2C2',
    },
    'blu notte': {
      hex: '#191970',
    },
    'fant.verde acqua/panna': {
      hex: '#83d8bc',
    },
    'fant.beige/azzurro/giallo': {
      hex: '#6380de',
    },
    'fant.cammello/azzurro': {
      hex: '#77dbf2',
    },
    'fant.giallo/arancio': {
      hex: '#e97a1a',
    },
    'grigio chiaro melange': {
      hex: '#D3D3D3',
    },
    fuxia: {
      hex: '#FF00FF',
    },
    'azzurro polvere': {
      hex: '#B0E0E6',
    },
    'giallo scuro': {
      hex: '#f1ed00',
    },
    'fant.cipria/blu/cuoio': {
      hex: '#bc6400',
    },
    'fant.arancio/nero': {
      hex: '#000000',
    },
    'rosa lillato': {
      hex: '#F8D0FF',
    },
    rosa: {
      hex: '#ffbce7',
    },
    beige: {
      hex: '#F5F5DC',
    },
  },
};

export interface IMappedOptionValue {
  hex: string;
}

export function getMappedOptionName(
  language: `${LanguageCode}`,
  name: TOptionName,
): string {
  return MAPPED_OPTIONS_NAME[language]?.[name] || name;
}

export function getMappedOptionValue(
  language: `${LanguageCode}`,
  value: string,
): IMappedOptionValue {
  return (
    MAPPED_OPTIONS_VALUES[language]?.[value.toLowerCase()] || {hex: '#D3D3D3'}
  );
}
